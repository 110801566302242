import { Form as AntForm } from 'antd';

const CustomFormItem = ((props) => {
  const { style, ...rest } = props;
  return <AntForm.Item style={{ marginBottom: 0, ...style }} {...rest} />;
}) as typeof AntForm.Item;

const Form = {
  ...AntForm,
  Item: CustomFormItem,
} as typeof AntForm & { Item: typeof CustomFormItem };

export { Form };
